<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button />
        </ion-buttons>
        <ion-title>{{ $route.name }}</ion-title>
        <ion-button @click="goToHome()" color="primary" fill="clear" slot="end">
          <ion-icon icon="home" />
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <!--ion-segment scrollable mode="md" color="tertiary" value="1" @ionChange="changeType($event)">
      <ion-segment-button mode="md" value="1" selected>
        <ion-label>Ultime formule</ion-label>
      </ion-segment-button>
    </ion-segment-->
    <ion-content>
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="secondary" @click="openSearchParams()">
          <ion-icon name="search" />
        </ion-fab-button>
      </ion-fab>
      <ion-loading :is-open="loading" cssClass="loading" message="Caricamento..." :duration="timeout" />
      <ion-list v-if="docs">
        <div v-for="n in docs" :key="n" lines="none">
          <jn-doc :doc="n" :showAbstract="false" />
        </div>
      </ion-list>

      <ion-infinite-scroll @ionInfinite="loadDocuments($event)" threshold="100px" id="infinite-scroll"
        :disabled="isDisabled">
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Caricamento...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import JnDoc from '../../components/DocCard.vue';
import { document, search } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { Document } from '@/models/document.model';
import { SearchData } from '@/models/search.model';
import { EsService } from '@/services/es.service';
import * as eventBus from '../../services/event.bus.js';
import { useRouter, useRoute } from 'vue-router';
import {
  Formulario,
  SearchTypes
} from '@/models/enums.model';

import JnFormularioSearchParams from '../search/FormularioSearchParams.vue';

addIcons({
  document: document,
  search: search,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
} from '@ionic/vue';

export default defineComponent({
  name: 'JnFormulario',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonMenuButton,
    JnDoc,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonLoading,
    IonIcon,
    IonButton,
    IonFab,
    IonFabButton
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const isDisabled = ref(false);
    const docs = ref([] as Document[]);
    const docsSize = ref();

    const loading = ref(true);
    const timeout = ref(5000);

    const searchData = ref(new SearchData({
      index: process.env.VUE_APP_JURANEWS_INDEXES,
      from: 0,
      size: 10,
      idCategory: [Formulario.ULTIME_FORMULE],
      withText: true,
      searchInMassime: false
    }));

    function goToHome() {
      router.push('/main');
    }

    const pushDocuments = async () => {
      searchData.value.from = docs.value.length;
      const newDocs = await EsService.getDocs(searchData.value as SearchData);
      for (let i = 0; i < newDocs.length; i++) {
        docs.value.push(newDocs[i]);
      }
    };

    onMounted(async () => {
      loading.value = true;
      docs.value = await EsService.getDocs(searchData.value as SearchData);
      docsSize.value = await EsService.countDocs(
        searchData.value as SearchData
      );
      loading.value = false;
      eventBus.default.$on('reset-results', function (data: string) {
        docs.value = [];
        pushDocuments();
      });
    });

    const loadDocuments = async (ev: CustomEvent) => {
      setTimeout(() => {
        pushDocuments();
        const target = ev.target as HTMLIonInfiniteScrollElement;
        target.complete();
        if (docs.value.length == docsSize.value) {
          target.disabled = true;
        }
      }, 500);
    };

    pushDocuments();

    const openSearchParams = async () => {
      const searchDataParams = ref(
        new SearchData({
          index: process.env.VUE_APP_JURANEWS_INDEXES,
          from: 0,
          size: 10,
          withText: true,
          idAreaTematica: [],
          searchType: SearchTypes.FORMULARIO,
          searchInMassime: false,
          idCategory: [Formulario.ULTIME_FORMULE]
        })
      );

      const modal = await modalController.create({
        component: JnFormularioSearchParams,
        componentProps: { searchData: searchDataParams.value },
        cssClass: 'searchModal',
      });

      modal.present();
      const result = await modal.onDidDismiss();
      if (result.data) {
        searchDataParams.value = result.data.value as SearchData;
        store.commit('searchData', searchDataParams.value);
        eventBus.default.$emit(
          'formulario-search-data',
          searchDataParams.value
        );
        router.push('/main/search');
      }
    };

    const changeType = async (ev: CustomEvent) => {
      loading.value = true;
      docs.value = [];
      searchData.value.from = 0;
      if (ev.detail.value == '1') {
        searchData.value.idCategory = [Formulario.ULTIME_FORMULE];
      }
      docs.value = await EsService.getDocs(searchData.value as SearchData);
      docsSize.value = await EsService.countDocs(
        searchData.value as SearchData
      );
      loading.value = false;
    };

    return {
      isDisabled,
      docs,
      loadDocuments,
      changeType,
      loading,
      timeout,
      openSearchParams,
      goToHome
    };
  },
});
</script>
